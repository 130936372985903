import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header from '../subcomponents/Header';
import Footer from '../subcomponents/Footer';

const LandingPage = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState({});
  const sectionRefs = useRef({});
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [tileVisibility, setTileVisibility] = useState({});
  const tileRefs = useRef([]);

  const addRef = (element) => {
    if (element && !tileRefs.current.includes(element)) {
      tileRefs.current.push(element);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 2000); // 2 seconds delay
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible((prev) => ({ ...prev, [entry.target.id]: entry.isIntersecting }));
        });
      },
      { threshold: 0.1 }
    );

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

 
  const handlePayerSelect = (payer) => {
    // Your logic for handling payer selection
  };


  return (
    <div className="landing-page">
      <Helmet>
        <title>Melbourne Water Company</title>
        <meta name="description" content="Whats in your water?" />
        <meta name="keywords" content="Melbourne Water Company" />
        <meta property="og:title" content="Melbourne Water Company" />
        <meta property="og:description" content="Whats in your water?" />
        <meta property="og:url" content="https://www.melbournewatercompany.com" />
      </Helmet>

      <Header />
      <main className="landing-content">
        <section className="hero-section" ref={el => sectionRefs.current['hero'] = el} id="hero">
          <div className="hero-container">
            <div className="hero-animation-wrapper">
            </div>
            <div className="hero-text">
              <h2 className={`hero-subtitle ${isVisible['hero'] ? 'animate' : ''}`}>Melbourne Water Company</h2>
              <p className={`hero-description ${isVisible['hero'] ? 'animate' : ''}`}>Whats in your water?</p>
              <div className={`cta-buttons ${isVisible['hero'] ? 'animate' : ''} ${isButtonVisible ? 'visible' : 'hidden'}`}>
                <button className="cta-button" onClick={() => navigate("/signup")}>Order Water</button>
              </div>
            </div>
          </div>
        </section>

        <section className="features-section grey-bg">
          <div className="">
            <section className="section-features">
              <div className="tiles-contents">
                <div className="grid-wrapper">
                  <div
                    className={`tile-patients tile tile-dark ${tileVisibility["patients"] ? "tile-visible" : "tile-animate"}`}
                    data-id="patients"
                    ref={addRef}
                  >
                    <div className="tile-content">
                      <div className="patients-wrapper">
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tile-billing tile tile-actually-white ${tileVisibility["billing"] ? "tile-visible" : "tile-animate"}`}
                    data-id="billing"
                    ref={addRef}
                  >
                    <div className="tile-content">
                      <div className="billing-wrapper">
                        
                        </div>
                    </div>
                  </div>
                  <div
                    className={`tile-setup tile tile-white ${tileVisibility["setup"] ? "tile-visible" : "tile-animate"}`}
                    data-id="setup"
                    ref={addRef}
                  >
                    <a href='/signin'>
                      <div className="tile-content">
                        <div className="setup-wrapper pb-5">
           
                          
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className={`tile-tools tile tile-dark ${tileVisibility["tools"] ? "tile-visible" : "tile-animate"}`}
                    data-id="tools"
                    ref={addRef}
                  >
    
                    <div className="tile-content">
                      <div className={`tile-tools tile ${tileVisibility["tools"] ? "tile-visible" : "tile-animate"}`} data-id="tools" ref={addRef}>
                        <div className="tile-content">
                          <div><FontAwesomeIcon style={{height: "70px", marginRight: "2rem"}} /></div>
                        
                        </div>
                      </div>                 
                    </div>
                  </div>

                  <div
                    style={{ backgroundImage: `url($)`, backgroundSize: "cover", backgroundPosition: "center" }}
                    className={`tile-providers tile tile-white ${tileVisibility["providers"] ? "tile-visible" : "tile-animate"}`}
                    data-id="providers"
                    ref={addRef}
                  >
                    <div className="tile-content tile-content-image">
                      <div className="providers-wrapper">
                        <h1 className="typography-design-headline">szdfasdfasdfasdfasdf</h1>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ backgroundImage: `url()`, backgroundSize: "cover", backgroundPosition: "center" }}
                    className={`tile-patientpic tile tile-white ${tileVisibility["patientpic"] ? "tile-visible" : "tile-animate"}`}
                    data-id="patientpic"
                    ref={addRef}
                  >
                    <div className="tile-content tile-content-image">
                      <div className="patientpic-wrapper">
                        <h1 className="typography-design-headline">asdfasdfasdfasdfasdf</h1>
                      </div>
                    </div>
                  </div>
          
                  <div
                    className={`tile-security tile tile-black ${tileVisibility["security"] ? "tile-visible" : "tile-animate"}`}
                    data-id="security"
                    ref={addRef}
                  > 
                    <div className="tile-content">
                      <div className="security-wrapper">
                        <FontAwesomeIcon style={{ height: "48px", margin: "1rem" }}/>
                        <h1 className="typography-design-headline text-center">dfgdfgdfg</h1>
                        <p className="typography-label-alt-white text-center">sdfgsdfgsdfg</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tile-glamour tile tile-white ${tileVisibility["glamour"] ? "tile-visible" : "tile-animate"}`}
                    data-id="glamour"
                    ref={addRef}
                  >
                    <div className="tile-content">
                      <div className="glamour-wrapper">
                        <h1 className="typography-design-headline-dark">In a relationship, communication is key.</h1>
                        <div className="messages-wrapper">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tile-demo tile tile-white ${tileVisibility["demo"] ? "tile-visible" : "tile-animate"}`}
                    data-id="demo"
                    ref={addRef}
                  >
                    <div className="tile-content">
                      <div className="demo-wrapper">
                        <h1 className="typography-design-headline-dark">See Popularis in action on YouTube.</h1>              
                            {/* YouTube Embed Code */}
                            <div className="video-responsive">
                          <iframe
                            src="https://www.youtube.com/embed/5qibmtPL9AA"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                     </div>
                    </div>
                  </div>

                  <div
                    className={`tile-IPA tile tile-black ${tileVisibility["IPA"] ? "tile-visible" : "tile-animate"}`}
                    data-id="IPA"
                    ref={addRef}
                  >
                    <div className="tile-content">
                      <div className="split-wrapper">
                        <h1 className="typography-design-headline">Popularis IPA: Coming Soon.</h1>
                        <p className="typography-label-alt-white">In numbers there is advantage. Join our Independent Physicians Association and we help expedite the guesome credentialing process.</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tile-gateway tile tile-white ${tileVisibility["gateway"] ? "tile-visible" : "tile-animate"}`}
                    data-id="gateway"
                    ref={addRef}
                  >
                    <div className="tile-content">
                      <div className="split-wrapper">
                        <h2 className="typography-design-headline-dark">We connect you to the Payer Gateway.</h2>
                        <p className="typography-label-alt-dark">3,500 Payers. We connect you to the US Healthcare System. Search below.</p>
                        <div className="payerSelectorContainer">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tile-support tile tile-white ${tileVisibility["support"] ? "tile-visible" : "tile-animate"}`}
                    data-id="support"
                    ref={addRef}
                  >
                    <a href='/support'>
                      <div className="tile-content">
                        <div className="support-wrapper">
                          <div className="support-icon-features">
                          </div>
                          <div>
                            <h1 className="typography-headline">The Popularis Team is always by your side.</h1>
                            <p className="typography-label-alt-dark">Support us always just a call, email, or text away.</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className="faq-section grey-bg">
          <div className="section-container">
            <h2 className="section-title">Frequently Asked Questions</h2>
          </div>
        </section>

        <section className="partners-section white-bg">
          <div className="section-container">
            <h2 className="section-title">Trusted Partners</h2>
            <div className="partner-logos">
      
            </div>
          </div>
        </section>
      </main>
      <Footer />

      <style jsx>{`
        body {
          overflow-x: hidden;
          max-width: 100%;
          margin: 0px;
        }

        .landing-page {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
          color: #1d1d1f;
          background-color: #fbfbfd;
          -webkit-font-smoothing: antialiased;
        }

        .hero-section {
          background-color: #00316f;
          color: #f5f5f7;
          height: 75vh;
          display: flex;
          position: relative;
          overflow: hidden;
          text-align: center;
        }

        .hero-container, .section-container {
          max-width: 980px;
          margin: 0 auto;
          padding: 0 22px;
          position: relative;
          z-index: 1;
        }

        .hero-animation-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }

        .plan-card {
             background-color: #00316f;
             padding: 10px;
             color: #fff;
        }

        .hero-text {
          margin-top: 15vh;
          z-index: 1;
        }

        .hero-title {
          font-size: 72px;
          line-height: 1.05;
          font-weight: 700;
          letter-spacing: -0.015em;
          margin-bottom: 10px;
        }

        .hero-subtitle {
          font-size: 48px;
          line-height: 1.08349;
          font-weight: 600;
          letter-spacing: -.002em;
          margin-bottom: 20px;
        }

        .hero-description {
          font-size: 24px;
          line-height: 1.16667;
          font-weight: 400;
          letter-spacing: .009em;
          margin-bottom: 40px;
        }
        .section-title {
          font-size: 48px;
          line-height: 1.08349;
          font-weight: 600;
          letter-spacing: -.003em;
          text-align: center;
          margin-bottom: 50px;
        }

        .section-description {
          font-size: 21px;
          line-height: 1.381;
          font-weight: 400;
          letter-spacing: .011em;
          text-align: center;
          margin-bottom: 40px;
          color: #86868b;
        }

        .cta-buttons.hidden {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.5s ease, visibility 0.5s ease;
        }

        .cta-buttons.visible {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.5s ease, visibility 0.5s ease;
        }

        .features-section, .patients-section, .tools-section, .payer-section, .communication-section, .pricing-section, .faq-section, .partners-section {
          padding: 30px 0;
        }

        .grey-bg {
          background-color: #f8f8f8;
        }

        .white-bg {
          background-color: #ffffff;
        }

        .feature-cards {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px;
        }

        .feature-card {
          background: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 20px;
          width: calc(33.333% - 20px);
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .feature-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }

        .feature-icon {
          font-size: 48px;
          color: #0071e3;
          margin-bottom: 20px;
        }

        .feature-card h3 {
          font-size: 24px;
          line-height: 1.16667;
          font-weight: 600;
          letter-spacing: .009em;
          margin-bottom: 10px;
        }

        .feature-card p {
          font-size: 17px;
          line-height: 1.47059;
          font-weight: 400;
          letter-spacing: -.022em;
        }

        .feature-image {
          max-width: 100%;
          height: auto;
          border-radius: 18px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        }

        .partner-logos {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 50px;
        }

        .partner-logos img {
          height: 50px;
          object-fit: contain;
        }

        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate {
          animation: fadeInUp 0.6s ease-out forwards;
        }

        @media only screen and (max-width: 1068px) {
          .hero-title {
            font-size: 56px;
          }

          .hero-subtitle {
            font-size: 36px;
          }

          .hero-description {
            font-size: 21px;
          }

          .section-title {
            font-size: 40px;
          }
        }

        @media only screen and (max-width: 734px) {
          .hero-title {
            font-size: 44px;
          }

          .hero-subtitle {
            font-size: 28px;
          }

          .hero-description {
            font-size: 18px;
          }

          .cta-button {
            font-size: 16px;
            padding: 10px 20px;
          }

          .section-title {
            font-size: 32px;
          }

          .feature-cards {
            flex-direction: column;
            align-items: center;
          }

          .feature-card {
            width: 100%;
          }

          .partner-logos {
            flex-direction: column;
            gap: 30px;
          }
        }

        .cta-buttons.hidden {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.5s ease, visibility 0.5s ease;
        }

        .cta-buttons.visible {
          opacity: 1;
          visibility: visible;
          animation: fadeIn 3.5s ease;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        .tile-setup {
          grid-area: setup;
        }

        .tile-tools {
          grid-area: tools;
        }

        .tile-patients {
          grid-area: patients;
        }

        .tile-billing {
          grid-area: billing;
        }

        .tile-providers {
          grid-area: providers;
        }

        .tile-patientpic {
          grid-area: patientpic;
        }

        .tile-glamour {
          grid-area: glamour;
        }

        .tile-security {
          grid-area: security;
        }

         .tile-demo {
          grid-area: demo;
        }

        .tile-metal {
          grid-area: metal;
        }

        .tile-IPA {
          grid-area: IPA;
        }

        .tile-gateway {
          grid-area: gateway;
        }

        .tile-continuity {
          grid-area: continuity;
        }

        .tile-support {
          grid-area: support;
        }

        .tile-dark {
          color: #f5f5f7;
          background: #00316f;
        }

        .tile-white {
          background: #f1f1f1;
        }

        .tile-actually-white {
           background: #fbfbfb;
        }

        .tile-black {
          color: #f5f5f7;
          background: #000000;
        }

        .tile {
          position: relative;
          border-radius: 20px;
          overflow: hidden;
          margin: 0 auto;
          display: flex;
          width: 100%;
          box-sizing: border-box;
        }

        .tile-content {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          width: 100%;
          padding: 25px !important;
        }

        .tile-content-hextials {
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 5rem;
          margin-bottom: 0rem;
        }

        .section-features {
          padding: 0px 25px;
          width: 61.8%;
          max-width: 1140px;
          margin: auto;
        }
        .grid-wrapper {
          display: grid;
          grid-template-rows: 640px 520px 720px 780px 700px 400px 650px;
          grid-auto-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-gap: 25px;
          grid-template-areas:
            "patients patients patients billing billing billing"
            "setup setup tools tools tools tools"
            "providers providers providers patientpic patientpic patientpic"
            "security security security security security security"
            "glamour glamour glamour glamour glamour glamour"
            "demo demo demo demo demo demo"
            "IPA IPA IPA gateway gateway gateway"
            "continuity continuity continuity continuity continuity continuity"
            "support support support support support support";
        }

        @media (max-width: 768px) {
          .grid-wrapper {
            grid-template-rows: repeat(9, auto);
            grid-template-areas:
              "patients"
              "billing"
              "setup"
              "tools"
              "providers"
              "patientpic"
              "security"
              "glamour"
              "demo"
              "IPA"
              "gateway"
              "continuity"
              "support";
          }

          .tile {
            grid-column: span 6;
          }
        }

        .tiles-contents {
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          max-width: 1390px;
        }

        .typography-design-headline {
          font-size: 32px;
          line-height: 1.0625;
          font-weight: 600;
          letter-spacing: -0.009em;
          color: #ffffff;
          padding-top: .25rem;
          padding-bottom: .25rem;
          text-align: left;
        }

        .typography-design-headline-dark {
          font-size: 32px;
          line-height: 1.0625;
          font-weight: 600;
          letter-spacing: -0.009em;
          color: #000000;
          padding-top: .25rem;
          padding-bottom: .25rem;
          text-align: left;
        }

                .landing-page {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
                    color: #1d1d1f;
                    background-color: #fbfbfd;
                    -webkit-font-smoothing: antialiased;
                }

                .hero-section {
                    background-color: #f5f5f7;
                    color: #00316f;
                    height: 75vh;
                    display: flex;
                    position: relative;
                    overflow: hidden;
                    text-align: center;
                }

                .hero-container, .section-container {
                    max-width: 980px;
                    margin: 0 auto;
                    padding: 0 22px;
                    position: relative;
                    z-index: 1;
                }

                .hero-animation-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                }

                .hero-text {
                    margin-top: 20vh;
                    z-index: 1;
                }

                .hero-title {
                    font-size: 72px;
                    line-height: 1.05;
                    font-weight: 700;
                    letter-spacing: -0.015em;
                    margin-bottom: 10px;
                }

                .hero-subtitle {
                    font-size: 48px;
                    line-height: 1.08349;
                    font-weight: 600;
                    letter-spacing: -.002em;
                    margin-bottom: 20px;
                }

                .hero-description {
                    font-size: 24px;
                    line-height: 1.16667;
                    font-weight: 400;
                    letter-spacing: .009em;
                    margin-bottom: 40px;
                }
                .section-title {
                    font-size: 48px;
                    line-height: 1.08349;
                    font-weight: 600;
                    letter-spacing: -.003em;
                    text-align: center;
                    margin-bottom: 50px;
                }

                .section-description {
                    font-size: 21px;
                    line-height: 1.381;
                    font-weight: 400;
                    letter-spacing: .011em;
                    text-align: center;
                    margin-bottom: 40px;
                    color: #86868b;
                }

                .cta-buttons.hidden {
                  opacity: 0;
                  visibility: hidden;
                  transition: opacity 0.5s ease, visibility 0.5s ease;
                }

                .cta-buttons.visible {
                  opacity: 1;
                  visibility: visible;
                  transition: opacity 0.5s ease, visibility 0.5s ease;
                }

                .features-section, .patients-section, .tools-section, .payer-section, .communication-section, .pricing-section, .faq-section, .partners-section {
                    padding-top: 30px;
                    padding-bottom: 70px;
                }

                .grey-bg {
                    background-color: #f8f8f8;
                }

                .white-bg {
                    background-color: #ffffff;
                }

                .feature-cards {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 20px;
                }

                .feature-card {
                    background: #fff;
                    border: 1px solid #e0e0e0;
                    border-radius: 10px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    padding: 20px;
                    width: calc(33.333% - 20px);
                    text-align: center;
                    transition: transform 0.3s ease, box-shadow 0.3s ease;
                }

                .feature-card:hover {
                    transform: translateY(-10px);
                    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
                }

                .feature-icon {
                    font-size: 48px;
                    color: #0071e3;
                    margin-bottom: 20px;
                }

                .feature-card h3 {
                    font-size: 24px;
                    line-height: 1.16667;
                    font-weight: 600;
                    letter-spacing: .009em;
                    margin-bottom: 10px;
                }

                .feature-card p {
                    font-size: 17px;
                    line-height: 1.47059;
                    font-weight: 400;
                    letter-spacing: -.022em;
                }

                .feature-image {
                    max-width: 100%;
                    height: auto;
                    border-radius: 18px;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
                }

                .partner-logos {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 50px;
                }

                .partner-logos img {
                    height: 50px;
                    object-fit: contain;
                }

                @keyframes fadeInUp {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .animate {
                    animation: fadeInUp 0.6s ease-out forwards;
                }

                @media only screen and (max-width: 1068px) {
                    .hero-title {
                        font-size: 56px;
                    }

                    .hero-subtitle {
                        font-size: 36px;
                    }

                    .hero-description {
                        font-size: 21px;
                    }

                    .section-title {
                        font-size: 40px;
                    }
                }

                @media only screen and (max-width: 734px) {
                    .hero-title {
                        font-size: 44px;
                    }

                    .hero-subtitle {
                        font-size: 28px;
                    }

                    .hero-description {
                        font-size: 18px;
                    }

                    .cta-button {
                        font-size: 16px;
                        padding: 10px 20px;
                    }

                    .section-title {
                        font-size: 32px;
                    }

                    .feature-cards {
                        flex-direction: column;
                        align-items: center;
                    }

                    .feature-card {
                        width: 100%;
                    }

                    .partner-logos {
                        flex-direction: column;
                        gap: 30px;
                    }
                }
                .pricing-table {
                  display: flex;
                  justify-content: space-around;
                  flex-wrap: wrap;
                  margin: 0 auto;
                  max-width: 1200px;
                }

                .pricing-card {
                  background: #fff;
                  border: 1px solid #e0e0e0;
                  border-radius: 10px;
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                  padding: 20px;
                  width: calc(33.333% - 20px);
                  margin: 10px;
                  text-align: center;
                  transition: transform 0.3s ease, box-shadow 0.3s ease;
                }

                .pricing-card:hover {
                  transform: translateY(-10px);
                  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
                }

                .pricing-card h3 {
                  font-size: 24px;
                  line-height: 1.16667;
                  font-weight: 600;
                  letter-spacing: .009em;
                  margin-bottom: 10px;
                }

                .pricing-card p {
                  font-size: 20px;
                  line-height: 1.47059;
                  font-weight: 400;
                  letter-spacing: -.022em;
                  margin-bottom: 20px;
                }

                .pricing-card ul {
                  list-style-type: none;
                  padding: 0;
                  margin: 0 0 20px;
                }

                .pricing-card ul li {
                  margin-bottom: 10px;
                  font-size: 16px;
                }

                .pricing-card button {
                  background-color: #0071e3;
                  color: #fff;
                  border: none;
                  padding: 10px 20px;
                  font-size: 16px;
                  border-radius: 5px;
                  cursor: pointer;
                  transition: background-color 0.3s ease;
                }

                .pricing-card button:hover {
                  background-color: #005bb5;
                }

                @media (max-width: 768px) {
                  .pricing-card {
                    width: calc(100% - 20px);
                  }
                }

                .plan-card ul { 
                  color: #fff;
                  font-weight: bold;
                }

                .plan-card ul li {
                    margin-bottom: 0.5rem;
                    color: #f1f1f1;
                    font-size: 0.9rem;
                }

                .plan-description {
                 color: #fff;
                                
                    height: 7rem;
                    overflow: hidden;
                }

                .price {
                color: #f1f1f1;
                }

                .pricing-table p {
                  color: #f1f1f1;
                }

                .pricing-table button {
                  color: #00316f;
                  background-color: #fff; 
                  padding: 8px 16px;
                  text-align: center;
                  text-decoration: none;
                  font-size: 16px;
                  margin: 4px 2px;
                  cursor: pointer;
                  border-radius: 50px;
                  margin-top: 20px;
                  box-shadow: none;
                  position: relative;
              }
             .month {
                  font-size: 1.2rem;
                  color: #fff;
              }
              .glamour-wrapper {
                width: 100%
              }
              .message-messageText {
                position: relative;
                display: block; /* Makes the element a block-level element */
                overflow: hidden;
                width: 100%;
                font-family: 'Courier New', Courier, monospace;
                white-space: normal; /* Allows text to wrap */
              }
                .features-apps-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Adjust grid layout for smaller screens */
@media (max-width: 1200px) {
  .features-apps-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .features-apps-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .features-apps-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}


            `}</style>
    </div>
  );
};

export default LandingPage;
