import React from 'react';
import { Link } from 'react-router-dom';

function Footer({ isDark }) { 

  return (
    <footer className={`footer ${isDark ? 'footer-dark' : ''}`}>
      
      <div className="logo-container">
        <img alt="Melbourne Water Company" className="logoFooter" />
      </div>
      <Link to="/signup" className="create-account-button">
        Create Account
      </Link>
      <ul className="footer-links">
        <li><Link to="/support" className="footer-link">Support</Link></li>
        <li><Link to="/blog" className="footer-link">Blog</Link></li>
        <li><Link to="/terms" className="footer-link">Terms of Service</Link></li>
        <li><Link to="/privacy" className="footer-link">Privacy Policy</Link></li>
        <li><Link to="/signup" className="footer-link">Sign Up</Link></li>
        <li><Link to="/signin" className="footer-link">Sign In</Link></li>
      </ul>
      <p className="all-rights-reserved">Made in USA</p>

      <p className="all-rights-reserved">&copy; 2024 Melbourne Water Company. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
