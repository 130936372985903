import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from '../subcomponents/Footer';
import Header from '../subcomponents/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassDollar, faStickyNote, faVideo, faHeart, faBrain } from '@fortawesome/free-solid-svg-icons';

const TechSpecs = () => {


    return (
        <div className="TechSpecs-page">
            <Helmet>
                <title>Tech Specs</title>
                <meta name="description" content="Whats in your water?" />
                <meta name="keywords" content="Melbourne Water Company" />
                <meta property="og:title" content="Melbourne Water Company" />
                <meta property="og:description" content="Whats in your water?" />
                <meta property="og:url" content="https://www.MelbourneWaterCompany.com" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/MelbourneWaterCompany.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />

            </Helmet>
            <Header />
            <div>
                <main>
                    <div className='section-content'>
                    <section>
                        <div className='laptopImage'>
                        <img src={Landing} alt="Notes, Files, Zoom, Claims, Eligibility. The full suite of tools for medical billing." className="bigger-image" />
                        </div>
                    </section>

                    <section className='glance-countainer'>
                        <h2 className='glance-section-header'>At a Glance</h2>
                        <div className='Glance-Grid-container'>
                            <div className='Glance-Grid-item'>
                                <span className='typography-specs-subheadline'>3,500+</span>
                                <p className='grid-p-class'>Payers</p>
                            </div>
                            <div className='Glance-Grid-item'>
                            <img src={HIPAA} alt="HIPAA telehealth for clinics and providers." className="prooflogoHIPAA" />

                            </div>
                            <div className='Glance-Grid-item'>
                            <FontAwesomeIcon icon={faMagnifyingGlassDollar} size="lg" style={{ height: '60px' }} />
                            <p className='grid-p-class'>Seamless eligibility checks for guaranteed reimbursement.</p>
                            </div>
                            <div className='Glance-Grid-item'>
                            <FontAwesomeIcon icon={faBrain} size="lg" style={{ height: '60px' }} />

                            <p className='grid-p-class'>AI-generated notes</p>
                            </div>
                            <div className='Glance-Grid-item'>
                            <FontAwesomeIcon icon={faHeart} size="lg" style={{ height: '60px' }} />
                            <p className='grid-p-class'>Intuitive Patients app</p>
                            </div>
                            <div className='Glance-Grid-item'>
                            <p className='grid-p-class-overhead'>Process</p>
                            <span className='typography-specs-subheadline'>1,500+</span>
                            <p className='grid-p-class'>Claims at a time</p>
                            </div>
                    </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Pricing</h2>
                        </div>
                            <div className='tech-specs-grid'>
                              <div className='tech-specs-grid-item'>
                                <ul className='tech-specs-list'>
                                    <li>
                                    <span><strong>Profesional Plan:</strong></span>
                                    </li>
                                    <li>
                                        <span>$199 / month</span>
                                    </li>
                                    <li>
                                        <span>$40c per claim, claim status, and eligibility request</span>
                                    </li>
                                </ul>
                              </div>
                              <div className='tech-specs-grid-item'>
                              <ul className='tech-specs-list'>
                                    <li>
                                    <span><strong>Enterprise Plan:</strong></span>
                                    </li>
                                    <li>
                                        <span>$1,499 / month</span>
                                    </li>
                                    <li>
                                        <span>$35c per claim, claim status, and eligibility request</span>
                                    </li>
                                </ul>
                              </div>
                            </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Clinics</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <ul className='tech-specs-list'>
                                    <li>
                                        <span>Public intake forms hosted at links set by the user</span>
                                    </li>
                                    <li>
                                        <span>Custom required fields</span>
                                    </li>
                                    <li>
                                        <span>Insurance ID camera</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Codes</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <ul className='tech-specs-list'>
                                    <li>
                                        <span>Diagnosis codes: ICD10</span>
                                    </li>
                                    <li>
                                        <span>Claim Status Codes</span>
                                    </li>
                                    <li>
                                        <span>Place of Service Codes</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Capacity</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                            <ul className='tech-specs-list'>
                                    <li>
                                        <span>Tested with up to 50,000 patients</span>
                                    </li>
                                    <li>
                                        <span>Tested with up to 20,000 claims</span>
                                    </li>
                                    <li>
                                        <span>Maximum of 1,500 claims per batch claim processing</span>
                                    </li>
                                    <li>
                                        <span>Maximum of 500 claims per batch eligibility check</span>
                                    </li>
                                    <li>
                                        <span>Processes up to 33 claims per minute with batch claims</span>
                                    </li>
                                    <li>
                                        <span>Batch patient uploads: 1,000 patients in 4.8 seconds</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

           

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Interoperability</h2>
                        </div>
                            <div className='tech-specs-grid'>
                              <div className='tech-specs-grid-item'>
                                <ul className='tech-specs-list'>
                                <li>
                                    <span><strong>Patient Data:</strong></span>
                                </li>
                                <li>
                                    <span>Encounters API: Callback for transmitting new encounters </span>
                                </li>
                                <li>
                                    <span>Lab Results API: API for updating lab results </span>
                                </li>
                                <li>
                                    <span>Individual patient data download (XLSX or PDF)</span>
                                </li>
                                <li>
                                    <span>Batch patient data download (XLSX)</span>
                                </li>
                                <li>
                                    <span>Batch patient upload (XLSX)</span>
                                </li>
                                </ul>
                              </div>
                              <div className='tech-specs-grid-item'>
                              <ul className='tech-specs-list'>
                                    <li>
                                    <span><strong>Claims Data:</strong></span>
                                    </li>
                                    <li>
                                        <span>Download CMS1500 forms</span>
                                    </li>
                                    <li>
                                        <span>Download a batch of sent claims (XSLX)</span>
                                    </li>
                                    <li>
                                        <span>Download from claims table with filters and search applied (XSLX)</span>
                                    </li>
                                    <li>
                                        <span>Create reports using demographics, codes, or payers (XSLX)</span>
                                    </li>
                                    <li>
                                        <span>Accounts Receivable Reports (XSLX or PDF)</span>
                                    </li>
                                </ul>
                              </div>
                            </div>
                    </section>

                    <section class='section-tech-specs'>
                        <div class='section-header-techspecs'>
                            <h2 class='section-headline'>System Requirements</h2>
                        </div>
                        <div class='tech-specs-column-row'>
                            <div class='grid tech-specs-grid-3wide'>
                                <div class='tech-specs-grid-item'>
                                    <ul class='tech-specs-list'>
                                        <li>
                                            <span><strong>Hardware Requirements:</strong></span>
                                        </li>
                                        <li>
                                            <span><em>Processor:</em> Minimum 1 GHz; Recommended 2 GHz or faster</span>
                                        </li>
                                        <li>
                                            <span><em>Memory:</em> Minimum 2 GB RAM; Recommended 4 GB or more</span>
                                        </li>
                                        <li>
                                            <span><em>Display:</em> Minimum resolution of 1024 x 768; Recommended resolution of 1920 x 1080</span>
                                        </li>
                                        <li>
                                        <span><em>Mobile Support:</em> Broad support for iPad. Limited mobile phone support.</span>
                                        </li>
                                    </ul>
                                </div>

                                <div class='tech-specs-grid-item'>
                                    <ul class='tech-specs-list'>
                                        <li>
                                            <span><strong>Software Requirements:</strong></span>
                                        </li>
                                        <li>
                                            <span><em>Operating System:</em> Windows 8.1 or later, macOS 10.13 or later, modern Linux distribution</span>
                                        </li>
                                        <li>
                                            <span><em>Web Browser:</em> Latest version of Google Chrome, Mozilla Firefox, Apple Safari, or Microsoft Edge</span>
                                        </li>
                                    </ul>
                                </div>

                                <div class='tech-specs-grid-item'>
                                    <ul class='tech-specs-list'>
                                        <li>
                                            <span><strong>Internet Connection:</strong></span>
                                        </li>
                                        <li>
                                            <span>Stable and reliable internet connection with at least 5 Mbps download speed</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Security</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <ul className='tech-specs-list'>
                                    <li>
                                        <span>HIPPA Compliant Cloud-Based Servers</span>
                                    </li>
                                    <li>
                                        <span>Unique user identification, strong authentication, and role-based access controls</span>
                                    </li>
                                    <li>
                                        <span>Two-Factor Authentication</span>
                                    </li>
                                    <li>
                                        <span>Zero-Trust Policy</span>
                                    </li>
                                    <li>
                                        <span>Server-Side Encryption and Decryption of data</span>
                                    </li>
                                    <li>
                                        <span>Detailed Activity Logs</span>
                                    </li>
                                    <li>
                                        <span>Geo-Fencing</span>
                                    </li>
                                    <li>
                                        <span>VPN and Dark-Web access disabled</span>
                                    </li>
                                    <li>
                                        <span>Safeguards established to protect ePHI from unauthorized alteration or destruction</span>
                                    </li>
                                    <li>
                                        <span>Regular ePHI backups and a disaster recovery in place</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>APIs</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <ul className='tech-specs-list'>
                                    <li>
                                        <span>Patient Encounters</span>
                                    </li>
                                    <li>
                                        <span>Lab test results</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Patient Data</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <div className='grid tech-specs-grid-3wide'>
                                    <div className='tech-specs-grid-item'>
                                        <ul className='tech-specs-list'>
                                            <li>
                                                 <span><strong>Basic Information:</strong></span>
                                            </li>
                                            <li>
                                                <span>Name</span>
                                            </li>
                                            <li>
                                                <span>Date of Birth</span>
                                            </li>
                                            <li>
                                                <span>Gender</span>
                                            </li>
                                            <li>
                                                <span>Address</span>
                                            </li>
                                            <li>
                                                <span>Phone</span>
                                            </li>
                                            <li>
                                                <span>Email</span>
                                            </li>
                                            </ul>
                                        </div>

                                    <div className='tech-specs-grid-item'>
                                        <ul className='tech-specs-list'>
                                            <li>
                                                 <span><strong>Medical History:</strong></span>
                                            </li>
                                            <li>
                                                <span>Family History</span>
                                            </li>
                                            <li>
                                                <span>Immunizations and Allergies</span>
                                            </li>
                                            <li>
                                                <span>Lab and Test Results</span>
                                            </li>
                                            <li>
                                                <span>Medication</span>
                                            </li>
                                            <li>
                                                <span>Vitals</span>
                                            </li>
                                            </ul>
                                        </div>

                            <div className='tech-specs-grid-item'>
                                    <ul className='tech-specs-list'>
                                        <li>
                                            <span><strong>Documents:</strong></span>
                                        </li>
                                        <li>
                                            <span>Notes with Signatures</span>
                                        </li>
                                        <li>
                                            <span>Files (any format)</span>
                                        </li>
                                        <li>
                                            <span>Encounters</span>
                                        </li>
                                        <li>
                                            <span>Claims</span>
                                        </li>
                                        <li>
                                            <span>Claims Status</span>
                                        </li>
                                        <li>
                                            <span>Eligibility Reports</span>
                                        </li>
                                    </ul>
                            </div>
                          </div>
                        </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Claims Data</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <div className='grid tech-specs-grid-3wide'>
                                    <div className='tech-specs-grid-item'>
                                            <ul className='tech-specs-list'>
                                                <li>
                                                    <span><strong>Providers:</strong></span>
                                                </li>
                                                <li>
                                                    <span>Billing</span>
                                                </li>
                                                <li>
                                                    <span>Rendering</span>
                                                </li>
                                                <li>
                                                    <span>Servicing</span>
                                                </li>
                                                <li>
                                                    <span>Referring</span>
                                                </li>
                                                </ul>
                                            </div>

                                <div className='tech-specs-grid-item'>
                                    <ul className='tech-specs-list'>
                                        <li>
                                            <span><strong>Service Lines:</strong></span>
                                        </li>
                                        <li>
                                            <span>Diagnosis Code(s)</span>
                                        </li>
                                        <li>
                                            <span>Charge amount</span>
                                        </li>
                                        <li>
                                            <span>Date of Service</span>
                                        </li>
                                        <li>
                                            <span>CPT Code + Modifiers</span>
                                        </li>
                                        </ul>
                                    </div>

                                <div className='tech-specs-grid-item'>
                                    <ul className='tech-specs-list'>
                                        <li>
                                        <span><strong>Additional Info:</strong></span>
                                         </li>
                                        <li>
                                            <span>CLIA Number</span>
                                        </li>
                                        <li>
                                            <span>Prior Authorization Number</span>
                                        </li>
                                        <li>
                                            <span>Claim Resubmission Number</span>
                                        </li>
                                    </ul>
                            </div>
                          </div>
                        </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Eligibility Reports</h2>
                        </div>
                            <div className='tech-specs-column-row'>


                                <div className='grid tech-specs-grid-3wide'>

                                <div className='tech-specs-grid-item'>
                                        <ul className='tech-specs-list'>
                                            <li>
                                                <span>Insurance Provider</span>
                                            </li>
                                            <li>
                                                <span>Plan Name & Type</span>
                                            </li>
                                            <li>
                                                <span>Coverage Start/End</span>
                                            </li>
                                            <li>
                                                <span>Subscriber Information</span>
                                            </li>
                                            </ul>
                                        </div>

                                    <div className='tech-specs-grid-item'>
                                        <ul className='tech-specs-list'>
                                            <li>
                                                <span>Eligibility Status</span>
                                            </li>
                                            <li>
                                                <span>Plan Number</span>
                                            </li>
                                            <li>
                                                <span>Group Number</span>
                                            </li>
                                            <li>
                                                <span>Plan Details</span>
                                            </li>
                                            <li>
                                                <span>Prior Auth Requirement</span>
                                            </li>
                                            </ul>
                                        </div>

                            <div className='tech-specs-grid-item'>
                                    <ul className='tech-specs-list'>
                                        <li>
                                            <span>Coverage Level</span>
                                        </li>
                                        <li>
                                            <span>Benefit Amount</span>
                                        </li>
                                        <li>
                                            <span>Insurance Types</span>
                                        </li>
                                    </ul>
                            </div>
                          </div>
                        </div>
                    </section>
                </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};


export default TechSpecs;
