import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import LandingPage from './components/Website/pages/LandingPage';
import TechSpecs from './components/Website/pages/Techspecs';

function App() {
 
  
  
  return (
    <Router>
        <Routes>
          <Route path="/" element={< LandingPage />} />
          <Route path="/techspecs" element={< TechSpecs />} />
          
          
        </Routes>
    </Router>
  );
}

export default App;
